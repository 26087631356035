import { Icon } from '@/Components'
import { cn } from '@/Lib'
import { InertiaLinkProps, Link, usePage } from '@inertiajs/react'
import * as NavigationMenuPrimitive from '@radix-ui/react-navigation-menu'
import {
  ComponentPropsWithoutRef,
  ElementRef,
  forwardRef,
  useMemo
} from 'react'

const VITE_APP_URL = import.meta.env.VITE_APP_URL || 'https://fynbos.money'

const NavigationMenu = forwardRef<
  ElementRef<typeof NavigationMenuPrimitive.Root>,
  ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Root>
>(({ className, children, ...props }, ref) => (
  <NavigationMenuPrimitive.Root
    ref={ref}
    className={cn('relative flex items-center', className)}
    {...props}
  >
    {children}
    <NavigationMenuViewport />
  </NavigationMenuPrimitive.Root>
))
NavigationMenu.displayName = NavigationMenuPrimitive.Root.displayName

const NavigationMenuList = forwardRef<
  ElementRef<typeof NavigationMenuPrimitive.List>,
  ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.List>
>(({ className, ...props }, ref) => (
  <NavigationMenuPrimitive.List
    ref={ref}
    className={cn('flex items-center gap-2', className)}
    {...props}
  />
))
NavigationMenuList.displayName = NavigationMenuPrimitive.List.displayName

const NavigationMenuItem = NavigationMenuPrimitive.Item

const NavigationMenuTrigger = forwardRef<
  ElementRef<typeof NavigationMenuPrimitive.Trigger>,
  ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
  <NavigationMenuPrimitive.Trigger
    ref={ref}
    className={cn(
      'group flex max-w-max items-center justify-center gap-1 rounded-full py-3 pl-5 pr-4 font-medium transition-colors hover:bg-nav hover:text-strong focus:bg-nav focus:text-strong focus:outline-none data-[state=open]:bg-nav data-[state=open]:text-strong data-[state=open]:hover:bg-nav data-[state=open]:focus:bg-nav-active [&[data-state=open]>span]:rotate-180',
      className
    )}
    {...props}
  >
    {children}
    <Icon className='transition-transform duration-200'>expand_more</Icon>
  </NavigationMenuPrimitive.Trigger>
))
NavigationMenuTrigger.displayName = NavigationMenuPrimitive.Trigger.displayName

const NavigationMenuContent = forwardRef<
  ElementRef<typeof NavigationMenuPrimitive.Content>,
  ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Content>
>(({ className, ...props }, ref) => (
  <NavigationMenuPrimitive.Content
    ref={ref}
    className={cn(
      'data-[motion^=from-]:animate-in data-[motion^=to-]:animate-out data-[motion^=from-]:fade-in data-[motion^=to-]:fade-out data-[motion=from-end]:slide-in-from-right-52 data-[motion=from-start]:slide-in-from-left-52 data-[motion=to-end]:slide-out-to-right-52 data-[motion=to-start]:slide-out-to-left-52 left-0 top-0 flex w-[300px] flex-col gap-2 p-2 md:absolute',
      className
    )}
    {...props}
  />
))
NavigationMenuContent.displayName = NavigationMenuPrimitive.Content.displayName

const NavigationMenuLink = forwardRef<
  ElementRef<typeof NavigationMenuPrimitive.Link>,
  InertiaLinkProps &
    ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Link>
>(({ className, href, children, ...props }, ref) => {
  const { url } = usePage()

  const isActive = useMemo(() => {
    const active = new URL(url, VITE_APP_URL)
    const current = new URL(href, VITE_APP_URL)

    if (active.pathname == '/' && current.pathname == '/') return true
    return (
      active.pathname.startsWith(current.pathname) && current.pathname != '/'
    )
  }, [url, href])

  return (
    <NavigationMenuPrimitive.Link asChild active={isActive}>
      <Link
        ref={ref}
        href={href || ''}
        className={cn(
          isActive ? 'bg-slate-50' : '',
          'group flex w-full items-center rounded-full px-5 py-3 transition-colors hover:bg-slate-100',
          className
        )}
        {...props}
      >
        {children}
      </Link>
    </NavigationMenuPrimitive.Link>
  )
})
NavigationMenuLink.displayName = 'NavigationMenuLink'

const NavigationMenuViewport = forwardRef<
  ElementRef<typeof NavigationMenuPrimitive.Viewport>,
  ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Viewport>
>(({ className, ...props }, ref) => (
  <div className={cn('absolute left-0 top-full flex justify-center')}>
    <NavigationMenuPrimitive.Viewport
      className={cn(
        'origin-top-center data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-90 relative mt-1.5 h-[var(--radix-navigation-menu-viewport-height)] w-full overflow-hidden rounded-card bg-white p-2 shadow-lg outline outline-base/30 md:w-[var(--radix-navigation-menu-viewport-width)]',
        className
      )}
      ref={ref}
      {...props}
    />
  </div>
))
NavigationMenuViewport.displayName =
  NavigationMenuPrimitive.Viewport.displayName

export {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  NavigationMenuViewport
}
