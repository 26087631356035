import {
  Colours,
  FynbosLogo,
  Icon,
  ProductIcon,
  Products,
  Router
} from '@/Components'
import { cn } from '@/Lib'
import { PageProps } from '@/types'
import { Learn } from '@/types/learn'
import { InertiaLinkProps, Link, usePage } from '@inertiajs/react'
import * as AccordionPrimitive from '@radix-ui/react-accordion'
import { motion, useMotionValueEvent, useScroll } from 'framer-motion'
import {
  FC,
  ReactNode,
  forwardRef,
  useMemo,
  useState,
  type RefAttributes
} from 'react'
import { NavDrawer } from '../NavDrawer'
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger
} from './NavigationMenu'

const VITE_APP_URL = import.meta.env.VITE_APP_URL || 'https://fynbos.money'

type Props = PageProps<Learn>

export const MarketingHeader: FC = () => {
  const { user, nav } = usePage<Props>().props

  return (
    <header className='sticky top-0 z-20 box-border flex w-full border-b border-slate-200 bg-white/95 md:h-24 [@supports(backdrop-filter:blur(0))]:bg-white/80 [@supports(backdrop-filter:blur(0))]:backdrop-blur'>
      <div className='flex w-full items-center gap-x-4 p-4 md:p-6'>
        <div className='lg:hidden'>
          <NavDrawer>
            <AccordionPrimitive.Root
              type='multiple'
              className={cn('flex flex-col gap-1', nav && 'mb-8')}
            >
              {nav &&
                nav.data.allLearnV2s?.map((record) => (
                  <AccordionPrimitive.Item key={record.id} value={record.id}>
                    <AccordionPrimitive.Header>
                      <AccordionPrimitive.Trigger className='flex w-full items-center justify-between rounded-xl p-3 text-sm text-medium hover:bg-nav data-[state=open]:text-strong [&[data-state=open]>span]:rotate-180'>
                        {record.title}
                        <Icon className='transition-transform duration-200'>
                          expand_more
                        </Icon>
                      </AccordionPrimitive.Trigger>
                    </AccordionPrimitive.Header>
                    <AccordionPrimitive.Content className='flex flex-col gap-1 pl-4 data-[state=open]:py-1'>
                      {record.children?.map((section) => (
                        <SideNavItem
                          href={section?.slug || ''}
                          key={section?.id}
                        >
                          {section?.title}
                        </SideNavItem>
                      ))}
                    </AccordionPrimitive.Content>
                  </AccordionPrimitive.Item>
                ))}
            </AccordionPrimitive.Root>
            <SideNavItem href={route('marketing.home')}>
              <Icon>home</Icon>Home
            </SideNavItem>
            <SideNavItem
              href={route('marketing.products.show', {
                page: 'emergency-savings'
              })}
            >
              <ProductIcon
                className='p-0.5'
                shapes={Products.emergency_savings}
                colour={Colours.green}
              />
              Emergency savings
            </SideNavItem>
            <SideNavItem
              href={route('marketing.products.show', { page: 'tfsa' })}
            >
              <ProductIcon
                className='p-0.5'
                shapes={Products.tfsa}
                colour={Colours.sky}
              />
              Tax-free investments
            </SideNavItem>
            <SideNavItem
              href={route('marketing.products.show', {
                page: 'kids-accounts'
              })}
            >
              <ProductIcon
                className='p-0.5'
                shapes={Products.kids}
                colour={Colours.indigo}
              />
              Kids accounts
            </SideNavItem>
            <SideNavItem
              href={route('marketing.products.show', {
                page: 'savings-pots'
              })}
            >
              <ProductIcon
                className='p-0.5'
                shapes={Products.savings_pots}
                colour={Colours.purple}
              />
              Savings pots
            </SideNavItem>
            <SideNavItem href={route('marketing.pricing')}>
              <Icon>sell</Icon>Pricing
            </SideNavItem>
            <SideNavItem href={route('learn')}>
              <Icon>local_library</Icon>Learn
            </SideNavItem>
            <SideNavItem href={route('marketing.press')}>
              <Icon>newspaper</Icon>News & Media
            </SideNavItem>
            <SideNavItem href={route('login')}>
              <Icon>arrow_right_alt</Icon>Log in
            </SideNavItem>
          </NavDrawer>
        </div>
        <Router href={'/'}>
          <FynbosLogo />
        </Router>
        <NavigationMenu className='ml-10 hidden lg:flex' delayDuration={0}>
          <NavigationMenuList>
            <NavigationMenuItem>
              <NavigationMenuTrigger>Products</NavigationMenuTrigger>
              <NavigationMenuContent>
                <NavigationMenuLink
                  href={route('marketing.products.show', {
                    page: 'emergency-savings'
                  })}
                  className='flex w-full items-center gap-2 rounded-xl p-3'
                >
                  <ProductIcon
                    className='size-4'
                    shapes={Products.emergency_savings}
                    colour={Colours.green}
                  />
                  Emergency savings
                </NavigationMenuLink>
                <NavigationMenuLink
                  href={route('marketing.products.show', { page: 'tfsa' })}
                  className='flex w-full items-center gap-2 rounded-xl p-3'
                >
                  <ProductIcon
                    className='size-4'
                    shapes={Products.tfsa}
                    colour={Colours.sky}
                  />
                  Tax-free investments
                </NavigationMenuLink>
                <NavigationMenuLink
                  href={route('marketing.products.show', {
                    page: 'kids-accounts'
                  })}
                  className='flex w-full items-center gap-2 rounded-xl p-3'
                >
                  <ProductIcon
                    className='size-4'
                    shapes={Products.kids}
                    colour={Colours.indigo}
                  />
                  Kids accounts
                  <div className='ml-auto rounded-full bg-indigo-100 px-4 py-1 text-xs font-medium text-indigo-500'>
                    New
                  </div>
                </NavigationMenuLink>
                <NavigationMenuLink
                  href={route('marketing.products.show', {
                    page: 'savings-pots'
                  })}
                  className='flex w-full items-center gap-2 rounded-xl p-3'
                >
                  <ProductIcon
                    className='size-4'
                    shapes={Products.savings_pots}
                    colour={Colours.purple}
                  />
                  Savings pots
                  <div className='ml-auto rounded-full bg-purple-100 px-4 py-1 text-xs font-medium text-purple-500'>
                    New
                  </div>
                </NavigationMenuLink>
              </NavigationMenuContent>
            </NavigationMenuItem>
            <NavigationMenuItem>
              <NavigationMenuLink href={route('marketing.pricing')}>
                Pricing
              </NavigationMenuLink>
            </NavigationMenuItem>
            <NavigationMenuItem>
              <NavigationMenuLink href={route('learn')}>
                Learn
              </NavigationMenuLink>
            </NavigationMenuItem>
            <NavigationMenuItem>
              <NavigationMenuLink href={route('marketing.press')}>
                News & Media
              </NavigationMenuLink>
            </NavigationMenuItem>
          </NavigationMenuList>
        </NavigationMenu>
        <div className='contents'>
          {user && (
            <NavItem className='ml-auto' href={route('home')}>
              Back to app
            </NavItem>
          )}
          {!user && (
            <>
              <NavItem className='ml-auto hidden sm:flex' href={route('login')}>
                Log in
              </NavItem>
              <StartButton className='ml-auto sm:ml-0' href={route('login')} />
            </>
          )}
        </div>
      </div>
    </header>
  )
}

type SideNavItemProps = {
  children?: ReactNode
  href: string
  className?: string
  type?: 'doc' | 'section'
}

const SideNavItem: FC<SideNavItemProps> = ({
  className,
  children,
  href,
  type
}) => {
  return (
    <Router
      href={href}
      className={cn(
        type == 'section' && 'pl-8',
        'relative flex w-full items-center gap-2 truncate rounded-xl p-3 focus-visible:outline focus-visible:outline-2 focus-visible:outline-focus',
        className
      )}
    >
      {children}
    </Router>
  )
}

SideNavItem.displayName = 'ListItem'

export const NavItem = forwardRef<
  HTMLAnchorElement,
  InertiaLinkProps & RefAttributes<HTMLAnchorElement>
>(({ className, href, children, ...props }, ref) => {
  const { url } = usePage()

  const isActive = useMemo(() => {
    const active = new URL(url, VITE_APP_URL)
    const current = new URL(href, VITE_APP_URL)

    if (active.pathname == '/' && current.pathname == '/') return true
    return (
      active.pathname.startsWith(current.pathname) && current.pathname != '/'
    )
  }, [url, href])

  return (
    <Link
      ref={ref}
      href={href}
      className={cn(
        isActive ? 'bg-slate-50' : '',
        'group flex w-full max-w-max items-center rounded-full px-8 py-4 transition-colors hover:bg-slate-100',
        className
      )}
      {...props}
    >
      <span className='font-medium leading-none text-medium'>{children}</span>
    </Link>
  )
})
NavItem.displayName = 'ListItem'

export const StartButton = forwardRef<
  HTMLAnchorElement,
  InertiaLinkProps & RefAttributes<HTMLAnchorElement>
>(({ className, ...props }, ref) => {
  const { scrollY } = useScroll()
  const [threshold, setThreshold] = useState<boolean>(false)

  const pageHeight = typeof window != 'undefined' ? window.innerHeight / 1.8 : 0

  useMotionValueEvent(scrollY, 'change', (latest) => {
    if (latest > pageHeight && !threshold) setThreshold(true)
    else if (latest < pageHeight && threshold) setThreshold(false)
  })

  return (
    <Link
      ref={ref}
      className={cn(
        'group flex w-full max-w-max items-center rounded-full bg-nav p-1 transition-colors hover:bg-nav-hover',
        className
      )}
      {...props}
    >
      <span className='ml-4 mr-4 font-medium leading-none text-medium'>
        Sign up
      </span>
      <motion.div
        className={cn(
          'flex items-center justify-center rounded-full bg-nav p-2 transition-colors duration-300 group-hover:bg-green-400 group-hover:text-white',
          threshold && 'bg-green-400 text-white'
        )}
      >
        <Icon>arrow_right_alt</Icon>
      </motion.div>
    </Link>
  )
})

StartButton.displayName = 'StartButton'
